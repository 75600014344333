import { Icons } from '@careacademy/ca-design-system'
import { defineComponent } from 'vue'

import { on } from '@/javascript/vuejs/helpers/emitEventHandler'

const { SVGClose } = Icons
const emits = { 'close': (): boolean => true }

export default defineComponent({
	name: 'ModalCloseButton',

	props: { ...on(emits) },

	emits,

	render() {
		return (
			<a
				class="standard-modal__close simple-modal__icon-close-button cursor-pointer"
				data-test="modal-close-button"
				onClick={(): void => this.$emit('close')}
			>
				<SVGClose svgClass={'block w-6 h-6 antialiased cursor-pointer'} />
			</a>
		)
	},
})
